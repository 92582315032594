<template>
  <div class="container mt-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/posts" tag="a">{{ $t('menu.posts') }}</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page" v-if="data">
          {{ data.title[trad] }}
        </li>
      </ol>
    </nav>
    <div class="card" v-if="data">
      <div class="row">
        <div class="col-12 col-md-3 image">
          <img :src="apiUrl + getCover" :alt="data.title.fr" />
        </div>
        <div class="col-12 col-md-9">
          <div class="row justify-content-between">
            <div class="col-5">
              <div class="categorie">
                {{ data.category.title[trad] }}
              </div>
              <hr />
            </div>
            <div class="col-3 col-md-2">
              <a-select
                :default-value="trad"
                v-model="trad"
                style="width: 4, 5rem; float: right"
                @change="handleChange"
              >
                <a-select-option v-if="data.title.fr" value="fr"> Fr </a-select-option>
                <a-select-option v-if="data.title.en" value="en"> En </a-select-option>
                <a-select-option v-if="data.title.ar" value="ar"> ع </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="date">
            <i class="fe fe-calendar"></i>
            {{
              moment(data.createdAt)
                .locale('fr', localization)
                .format('DD/MM/YYYY HH:mm')
            }}
          </div>
          <h3 class="title">
            {{ data.title[trad] }}
          </h3>
          <div class="description pr-3" v-if="data.desc" v-html="data.desc[trad]"></div>
          <div v-for="(file, index) in data.files" :key="index">
            <a @click="inc('download')" :href="apiUrl + file" target="_blank">
              <button type="button" class="btn btn-danger btn-lg btn3d">
                {{ $t('general.download') }} <i class="ml-2 fe fe-download"></i>
              </button>
            </a>
            <div class="downloads">{{ data.download + ' ' + $t('general.downloads') }}</div>
          </div>
          <div class="watched">{{ data.seen }} <i class="fe fe-eye"></i></div>
        </div>
      </div>
    </div>
    <div v-else class="m-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-grow text-primary" role="status"></div>
      </div>
    </div>
  </div>
</template>
<script>
import apiAxios from '@/services/axios'
import moment from 'moment'
import localization from 'moment/locale/fr'
import axios from 'axios'

export default {
  name: 'Post',
  data: () => ({
    data: null,
    apiUrl: process.env.VUE_APP_API_URL,
    localization,
    trad: 'fr',
  }),
  created() {
    document
      .querySelector("meta[property='og:url']")
      .setAttribute('content', 'https://www.ites.tn' + this.$route.fullPath)
    this.getData()
  },
  computed: {
    getCover() {
      if (this.data.coverImg && this.data.coverImg[this.trad]) return this.data.coverImg[this.trad]
      else return this.data.coverImg
    },
  },
  methods: {
    moment,
    handleChange(value) {
      this.trad = value
    },
    getData() {
      apiAxios
        .post('/publications/filter', {
          query: { _id: this.$route.params.id, status: 'active' },
        })
        .then(res => {
          if (res.data[0].title.en && this.$i18n.locale == 'en-US') {
            this.trad = 'en'
          }

          if (res.data[0].title.ar && this.$i18n.locale == 'ar-EG') {
            this.trad = 'ar'
          }

          this.data = res.data[0]
          let coverImg =
            this.data.coverImg && this.data.coverImg[trad]
              ? this.data.coverImg[trad]
              : this.data.coverImg
          this.inc('seen')
          document
            .querySelector("meta[property='og:image']")
            .setAttribute('content', this.apiUrl + coverImg)
        })
        .finally(() => {
          //this.tableLoading=false
        })
    },
    trimWords(value, number) {
      if (!value) return ''
      if (value.split(' ').length < number) return value
      return (
        value
          .split(' ')
          .splice(0, number)
          .join(' ') + '...'
      )
    },
    async inc(val) {
      //ip control
      fetch('https://www.cloudflare.com/cdn-cgi/trace')
        .then(response => response.text())
        .then(response => {
          response = response
            .trim()
            .split('\n')
            .reduce(function(obj, pair) {
              pair = pair.split('=')
              return (obj[pair[0]] = pair[1]), obj
            }, {})
          let data = {
            seenIps: response.ip,
            downloadIps: response.ip,
          }
          axios
            .post(
              this.apiUrl + '/public/publication/' + this.$route.params.id + '/inc/' + val,
              data,
            )
            .catch(e => console.log(e))
        })
    },
    ToText(HTML) {
      var input = HTML
      return input
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, ' ')
        .replace(/ /g, ' ')
        .replace(/>/g, ' ')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.image > img {
  width: 100%;
}

.title {
  margin-bottom: 15px;
}

.card {
  border-radius: 0;
  border: 1px solid $primary;
  padding: 2px;
}

.categorie {
  font-size: 14px;
  font-style: italic;
  text-align: left;
}

hr {
  border-bottom: 2px solid $primary;
  width: 7em;
  margin: 0;
}

.date {
  color: $primary;
}

.icons a {
  padding-left: 7px;
  padding-right: 7px;
  padding: 10px;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  background-color: $primary;
  color: white;
}
.icons a:hover {
  background: white;
  background-color: rgba(0, 0, 0, 0.05);
  color: $primary !important;
}

.downloads {
  font-size: 12px;
  font-style: italic;
}

.watched {
  color: $primary;
  align-items: center;
  align-self: center;
  align-content: center;
  font-size: 18px;
  margin: 15px;

  font-weight: 600;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-danger {
  box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #aa0000,
    0 8px 8px 1px rgba(0, 0, 0, 0.5) !important;
  background-color: #d73814;
}

.btn3d.btn-danger:active,
.btn3d.btn-danger.active {
  box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3) !important;
  background-color: #d73814;
}
.description {
  text-align: justify;
  text-justify: inter-word;
}
</style>
